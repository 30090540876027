import { makeStyles } from '@material-ui/core/styles'
import { NoEncryption } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#E8EFFD',
    },
    whatsapp: {
        border: '2px solid green',
        position: 'fixed',
        bottom: '10%',
        right: '10%',
    },
    form: {
        width: "80%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    text: {
        fontSize: "14px",
        marginBottom: "5px"
    },
    formControl: {
        width: "100%",
        marginTop: "1.5rem"
    },
    submit: {
        margin: theme.spacing(4, 0, 0, 0),
        minWidth: `100%`,
    },
    mail: {
        textDecoration: 'none',
        color: '#1393EA'
    }
}));

export { useStyles }