import React, { useContext } from "react"
import {
  Container,
  Box,
  useMediaQuery,
  Grid,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core"
import Button from "../../Button"
import * as Yup from "yup"
import { useTheme } from "@material-ui/styles"
import { useStyles } from "./style"
import Paper from "../../Paper"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../FormErrors"
import loanContext from "../../../context/loan/loanContext"

const ContactLayout = () => {
  const { isSubmitting, submitContact } = useContext(loanContext)

  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const initialValues = {
    name: "",
    company: "",
    phoneNumber: "",
    email: "",
    message: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "name",
              })
            : true
        },
      })
      .min(3, "Not less than 3 characters")
      .required("Name is empty"),
    // company: Yup.string().required("Company is empty"),
    phoneNumber: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .min(9, "Phone Number must not be less than 9 characters")
      .required("Phone Number is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    message: Yup.string().required("Message is empty"),
  })

  const onSubmit = (values, resetForm) => {
    submitContact(values, resetForm)
  }

  return (
    <Box
      id="contact"
      position="relative"
      paddingTop={"9rem"}
      paddingBottom="6.25rem"
      className={classes.root}
    >
      <Container maxWidth={"lg"}>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={5}>
              <Box marginTop="5rem">
                <Typography
                  variant="body1"
                  style={{ color: "#1393EA" }}
                  gutterBottom
                >
                  CONTACT US
                </Typography>
                <Typography
                  variant="h1"
                  className={classes.headerTitle}
                  gutterBottom
                >
                  Chat With Us
                </Typography>
                <Typography variant="body1" className={classes.headerSubtitle}>
                  Whether you’re a start-up or a Fortune 500, we’d love to chat
                  about our product and how it meets your business needs.
                </Typography>
                <Box marginTop="2rem">
                  <Typography variant="body1" style={{ marginTop: "1rem" }}>
                    <h3 style={{ marginBottom: "4px" }}>
                      United States Office
                    </h3>
                    <span>
                      24285 Katy Freeway Suite 300, Katy 77494,
                      <br />
                      Texas, U.S.&nbsp;
                    </span>
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "1rem" }}>
                    <h3 style={{ marginBottom: "4px" }}>
                      United Arab Emirates (UAE) Office
                    </h3>
                    <span>
                      Dubai Digital Park, A5 Building DTEC, <br />
                      Dubai Silicon Oasis, Dubai, UAE.
                      <br />
                    </span>
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "1rem" }}>
                    <h3 style={{ marginBottom: "4px" }}>Nigeria Office</h3>
                    <span>
                      Vibranium Valley <br />
                      42, Local Airport Road, Ikeja, Lagos
                      <br />
                    </span>
                  </Typography>

                  <Typography variant="body1" style={{ marginTop: "3rem" }}>
                    <h3 style={{ marginBottom: "4px" }}>Partner Enquiry</h3>
                    <span>+1 512 666 3985</span> <br />
                    <span>+234 708 063 1839</span> <br />
                    <span>+234 915 072 9915</span> <br />
                    <a
                      href="mailto:clientservice@bursery.com"
                      className={classes.mail}
                    >
                      {" "}
                      clientservice@bursery.com{" "}
                    </a>
                  </Typography>

                  <Typography variant="body1">
                    <h3 style={{ marginBottom: "4px" }}>
                      Loan Enquiries & Support
                    </h3>
                    <span>+1 512 666 3985</span> <br />
                    <span>+234 708 063 1839</span> <br />
                    <a
                      href="mailto:support@bursery.com"
                      className={classes.mail}
                    >
                      {" "}
                      support@bursery.com{" "}
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Box marginTop="3rem" display="flex" justifyContent="flex-end">
                <Box height="100%" className={classes.form}>
                  <Paper
                    padding={isMobile ? "1rem 1.5rem" : "2rem 2.5rem 3rem"}
                    borderRadius="0"
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(initialValues, { resetForm }) => {
                        onSubmit(initialValues, resetForm)
                      }}
                    >
                      {({ getFieldProps }) => (
                        <Form noValidate autoComplete="off">
                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Full name
                            </Typography>
                            <TextField
                              name="name"
                              id="name"
                              required
                              placeholder="eg. Samuel Fabayo"
                              variant="outlined"
                              {...getFieldProps("name")}
                              size="small"
                            />
                            <ErrorMessage name="name" component={FormErrors} />
                          </FormControl>
                          {/* 
                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Company (Optional)
                            </Typography>
                            <TextField
                              name="company"
                              id="company"
                              // placeholder="Global Company"
                              {...getFieldProps("company")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="company"
                              component={FormErrors}
                            />
                          </FormControl> */}
                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Phone Number
                            </Typography>
                            <TextField
                              name="PhoneNumber"
                              id="phoneNumber"
                              placeholder="+234 534 7568498"
                              {...getFieldProps("phoneNumber")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component={FormErrors}
                            />
                          </FormControl>

                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Email
                            </Typography>
                            <TextField
                              name="email"
                              id="email"
                              required
                              placeholder="email@xyz.com"
                              {...getFieldProps("email")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage name="email" component={FormErrors} />
                          </FormControl>

                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Your Message
                            </Typography>
                            <TextField
                              name="message"
                              id="message"
                              fullWidth
                              required
                              multiline
                              placeholder="Enter message here"
                              rows={4}
                              {...getFieldProps("message")}
                              variant="outlined"
                              size="small"
                              helperText="This field is limited to 300 characters"
                            />
                            <ErrorMessage
                              name="message"
                              component={FormErrors}
                            />
                          </FormControl>

                          <FormControl className={classes.submit}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              type="submit"
                            >
                              Send Message
                            </Button>
                          </FormControl>
                        </Form>
                      )}
                    </Formik>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactLayout
