import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import ContactLayout from '../components/Layout/ContactLayout'

const Contact = () => {
    return (
        <>
            <SEO title="Contact" />
            <Layout withFooter withHeader>
                <Box>
                    <ContactLayout />
                </Box>
            </Layout>
        </>
    )
}

export default Contact
